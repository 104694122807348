import { render, staticRenderFns } from "./KanbanPreview.vue?vue&type=template&id=74a7478e&scoped=true&"
import script from "./KanbanPreview.vue?vue&type=script&setup=true&lang=ts&"
export * from "./KanbanPreview.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./KanbanPreview.vue?vue&type=style&index=0&id=74a7478e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a7478e",
  null
  
)

export default component.exports